@font-face {
    font-family: "roundabout-iconset";
    src: url("./roundabout-iconset.eot?e02fc4af3a4b66b4e6caf1ba6d9806b0?#iefix") format("embedded-opentype"),
url("./roundabout-iconset.woff2?e02fc4af3a4b66b4e6caf1ba6d9806b0") format("woff2"),
url("./roundabout-iconset.woff?e02fc4af3a4b66b4e6caf1ba6d9806b0") format("woff"),
url("./roundabout-iconset.ttf?e02fc4af3a4b66b4e6caf1ba6d9806b0") format("truetype"),
url("./roundabout-iconset.svg?e02fc4af3a4b66b4e6caf1ba6d9806b0#roundabout-iconset") format("svg");
}

i[class^="ra-"]:before, i[class*=" ra-"]:before {
    font-family: roundabout-iconset !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ra-arrow-right:before {
    content: "\f101";
}
.ra-bug:before {
    content: "\f102";
}
.ra-camera-switch:before {
    content: "\f103";
}
.ra-chat:before {
    content: "\f104";
}
.ra-check:before {
    content: "\f105";
}
.ra-chevron-down:before {
    content: "\f106";
}
.ra-circle:before {
    content: "\f107";
}
.ra-collapse-full:before {
    content: "\f108";
}
.ra-collapse:before {
    content: "\f109";
}
.ra-copy:before {
    content: "\f10a";
}
.ra-date:before {
    content: "\f10b";
}
.ra-delete:before {
    content: "\f10c";
}
.ra-emoji:before {
    content: "\f10d";
}
.ra-expand-full:before {
    content: "\f10e";
}
.ra-expand:before {
    content: "\f10f";
}
.ra-grid:before {
    content: "\f110";
}
.ra-help:before {
    content: "\f111";
}
.ra-invite:before {
    content: "\f112";
}
.ra-lightbulb:before {
    content: "\f113";
}
.ra-micro-muted:before {
    content: "\f114";
}
.ra-micro:before {
    content: "\f115";
}
.ra-nav:before {
    content: "\f116";
}
.ra-options:before {
    content: "\f117";
}
.ra-password:before {
    content: "\f118";
}
.ra-screensharing-off:before {
    content: "\f119";
}
.ra-screensharing:before {
    content: "\f11a";
}
.ra-send:before {
    content: "\f11b";
}
.ra-settings:before {
    content: "\f11c";
}
.ra-signout:before {
    content: "\f11d";
}
.ra-star:before {
    content: "\f11e";
}
.ra-time:before {
    content: "\f11f";
}
.ra-times:before {
    content: "\f120";
}
.ra-user:before {
    content: "\f121";
}
.ra-userlist:before {
    content: "\f122";
}
.ra-video-off:before {
    content: "\f123";
}
.ra-video:before {
    content: "\f124";
}
.ra-volume-muted:before {
    content: "\f125";
}
.ra-volume:before {
    content: "\f126";
}
.ra-warning:before {
    content: "\f127";
}
