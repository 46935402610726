@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("../font/Montserrat-Light.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("../font/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("../font/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("../font/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("../font/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("../font/Montserrat-Black.ttf");
}

