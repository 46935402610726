// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;

// Changing the body background and text
//$body-bg: #d3e9eb;
//$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Changing the theme colors
//$primary: #202f41;
//$secondary: #436296;
//$success: #2bc550;
//$info: #495dff;
//$warning: #ef8143;
//$danger: #ff293a;
//$light: #dfe6ee;
//$dark: #0f1319;


// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);

// Settings
$enable-shadows: false;


// Colors
$primary: #08CE95;
$light-shade: #86B1B7;
$light: #EBEBEB;
$white: #EBEBEB;
$secondary: $light-shade;
$dark-shade: #2E2F40;
$dark-shade-accent: #414251;
$dark-shade-ligthen: #47485A;
$dark: #2E2F40;

$success: #32C532;
$danger: #DB5959;
$theme-colors: (
        "primary": $primary,
        "light-shade": $light-shade,
        "dark-shade": $dark-shade,
        "success": $success,
        "danger": $danger
);
$body-bg: $dark-shade-ligthen;
$body-color: $light;
$link-color: $light;

// Typographie
//$line-height-base: 1.75;
$font-size-base: 1rem;
$font-family-base: 'Montserrat';
$font-family-sans-serif: 'Montserrat';
$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.25 !default;
$h3-font-size: $font-size-base * 1.625 !default;
$h4-font-size: $font-size-base * 1.375 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// Buttons
$btn-active-box-shadow: none;
$btn-focus-width: 0;
$btn-border-radius: 0;
$btn-font-weight: 500;
$btn-line-height: 35px;
$btn-font-size: 1.5rem;
$btn-padding-y: 0.75rem;
$btn-padding-x: 1rem;

// Card
$card-bg: $dark-shade;
$card-border-radius: 0;
$card-spacer-y: 2.3rem;
$card-border-width: 0;

// Input
$input-bg: $dark-shade;
$input-color: $white;
$input-group-addon-bg: $dark-shade;
$input-group-addon-color: $white;
$input-border-color: $white;
$input-focus-box-shadow: none;
$input-focus-border-color: $secondary;
//$input-padding-y: 1.125rem;
//$input-padding-x: 1.1875rem;

// Navbar
$navbar-padding-x: 0;
$navbar-brand-padding-y: 0.6875rem;

// List Group
$list-group-bg: $dark-shade-accent;
$list-group-border-radius: 0;
$list-group-border-color: $dark-shade;
$list-group-border-width: 0.125rem;
$list-group-item-padding-y: 0.625rem;

// Links
$link-hover-decoration: none;

// Modal
$modal-content-bg: $dark-shade;
$modal-header-padding: 0.9375rem;
$modal-title-line-height: 1.25rem;
$modal-content-border-color: none;
$modal-header-border-color: $dark-shade-ligthen;
$modal-content-border-radius: 0;

// Nav Tabs
$nav-tabs-border-width: 0.375rem;
$nav-tabs-border-width: 6px;
$nav-tabs-border-color: $dark-shade-ligthen;
$nav-tabs-link-active-border-color: transparent transparent $primary;
$nav-tabs-link-hover-border-color: $nav-tabs-link-active-border-color;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-color: $light;

// Code
$code-color: $primary;

// Progress Bar
$progress-bg: $dark-shade-accent;
