// Add additional styles here. For example, overwrite certain styles or add new components.
// Tip: You can use bootstrap's powerful mixins here!


//.alert-myalert {
//  @include alert-variant(#60667d, #1d1d1d, #f4fdff);
//}

//@each $color, $value in $theme-colors {
//  .alert-#{$color} {
//    box-shadow: 3px 3px theme-color-level($color, -3);
//  }
//}

.opacity-0 {
  opacity: 0 !important;
}

.profile-image-overlay {
  background: radial-gradient($dark-shade, black);
  opacity: 1;
  transition: opacity 1000ms linear;

  img {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    margin: auto;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

#root {
  height: 100vh;
  display: flex;
  overflow: hidden;
}

@include media-breakpoint-down(xs) {
  #root {
    flex-direction: column;
  }
}

// Common
label {
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: bold;
}

.mt-2rem {
  margin-top: 2.75rem;
}

em {
  font-size: 0.5rem;
  font-weight: 500;
  font-style: normal;
}


// Typographie
h1, .h1 {
  line-height: 3.75rem;
}

h2, .h2 {
  line-height: 2.375rem;
}

h3, .h3 {
  line-height: 2rem;
}

h4, .h4 {
  line-height: 1.875rem;
}

h5, .h5 {
  line-height: 1.875rem;
}

h6, .h6 {
  line-height: 1.5rem;
  font-weight: bold;
}

li {
  line-height: 2rem;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 0.25rem;
    padding: 0 0.0625rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $light-shade;

    &:hover {
      background-color: darken($white, 60%);
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
}

// Buttons
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    min-width: 10.625rem;
    @include button-variant($value, $value, lighten($value, 20%), lighten($value, 20%), darken($value, 10%), darken($value, 10%));

    &.disabled,
    &:disabled {
      filter: grayscale(30%);
    }

    @if $color == 'primary' {
      color: $dark-shade;

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      &.disabled,
      &:disabled {
        color: $dark-shade;
      }
    }

    &.disabled,
    &:disabled {
      filter: grayscale(40%);
    }
  }
}

.buttonText {
  position: absolute;
  text-transform: uppercase;
  font-size: 10px;
  left: 0;
  width: 100%;
  top: 35px;
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value, lighten($value, 20%), darken($value, 10%), darken($value, 10%));

    @include hover {
      color: lighten($value, 20%);
      background-color: transparent;
      border-color: lighten($value, 20%);
    }

    &.disabled,
    &:disabled {
      filter: grayscale(60%);
    }
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-icon {
  font-size: 1.25rem;
  font-weight: $font-weight-normal;
  color: $white;
  text-decoration: none;
  border: 0;
  padding: 0.75rem 1.25rem;

  &.btn-sm {
    padding: 0 $btn-padding-x;
    margin: 0;
    background-color: transparent;
  }

  @include hover {
    color: $white;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  &.menu-btn {
    &:not(.active):not(:hover) {
      border-bottom: 1px solid $dark-shade-ligthen;
    }

    &:hover,
    &:active,
    &.active{
      background-color: $dark-shade-ligthen;
      border-bottom: 1px solid $dark-shade;
    }
  }

  &.btn-icon-only {
    padding: 0;
    line-height:1rem;
  }
}

// Button Badges

.btn {
  position: relative;

  .badge {
    font-size: 0.75rem;
    line-height: 1rem;
    position: absolute;
    font-weigt: 700;
    top: 4px;
    right: 4px;
  }

  .chat-badge {
    top: 20px;
    right: 20px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    background-color: #ec6e14;
    line-height: 0.5rem;
    font-size: 10px;
  }

  .chat-badge-mobile {
    top: 0;
    right: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    background-color: #ec6e14;
    line-height: 0.5rem;
    font-size: 10px;
  }
}

// Invite Links

.code.inviteLinkInput {
  width: 80%;
  border: 1px solid $light;
  border-right: none;
  background-color: $dark-shade;
  color: $light;

  &:focus {
    outline: 0;
  }
}

.inviteLinkLabel {
  font-size: 10px;
}

.inviteLinkButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid $light;
  background-color: $dark-shade;
  color: $white;
  height: 4.625rem;

  &:hover {
    color: $primary;
    cursor: pointer;
  }

}

.inviteLinkInputGroup {
  justify-content: flex-start;
}

// Input Groups
.input-group {
  > .input-group-prepend > .input-group-text {
    border-right: 0;
    padding-right: 0.15rem;
    padding-bottom: 0.15rem;
    font-size: 1.25rem;
  }

  > .input-group-append > .input-group-text {
    border-left: 0;
    padding-left: 0.15rem;
  }

  .form-control + .input-group-prepend:not(:first-child) > .input-group-text {
    order: 0;
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.ordered {
    .form-control:not(:last-child) {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
  }

  .form-control:focus + .input-group-prepend > .input-group-text,
  .form-control:focus + .input-group-append > .input-group-text {
    color: $input-focus-border-color;
    border-color: $input-focus-border-color;
  }

  &:focus {
    .input-group-text {
      color: $input-focus-color;
      border-color: $input-focus-border-color;
    }
  }

  // ordering
  .input-group-prepend {
    order: 1;
  }
  .form-control {
    order: 2;
  }
  .input-group-append {
    order: 3;
  }
}

// Cards

.card-centered {
  min-width: 320px;
  max-width: 600px;
  margin: 0 auto;
}

.card-header {
  padding-top: $card-spacer-y;
  padding-bottom: 0;

  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 98%;
    padding-top: $card-spacer-y;
    border-bottom: 1px solid  $dark-shade-ligthen;
  }
}

.card-body {
  padding: $card-spacer-y 3rem;
}

// Toolbar
aside.navbar {
  width: 76px;
  display: block;
  padding-top: 0;
  padding-bottom: 0;

  .navbar-menu-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    button {
      height: 4.625rem;
      width: 100%;
    }
  }
}

.navbar-menu {
  height: calc(100% - 60px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid $dark-shade-ligthen;
  border-right: 1px solid $dark-shade-ligthen;
}

.navbar-brand {
  border-right: 1px solid $dark-shade-ligthen;
  padding-right: 1.1rem;
  padding-left: 1.1rem;
}

nav.navbar {
  .navbar-brand {
    padding: 0;
  }

  .navbar-menu {
    height: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    border: 0;
  }
}

.mobile-toolbar {
  padding: $navbar-padding-y $navbar-padding-x;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.stopScreenshareHoveringText {
  cursor: pointer;
}

.hoveringToolbarMenuGroup {
  z-index: 1;
  background: rgba(46, 47, 64, .7);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  &.screensharing {
    width: 300px;
    height: 100px;
  }
}

.hoveringStopScreenshareButton {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 0;
  border-top-right-radius: 50px;
  font-size: 12px;
  height: 100%;
  width: 200px;
}

.hoveringStreamControlls {
  position: absolute;
  left: 50vw;
  top: 10px;
  transform: translateX(-50%);
}

// Panel
.panel {
  flex: 0 0 18.75rem;
  width: 18.75rem;
}

.panel-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.8125rem;
  border-bottom: 1px solid $dark-shade-ligthen;
}

.panel-body {
  height: calc(100% - 61px);

  &.chat-open {
    height: calc(100% - 101px);
  }

}

@include media-breakpoint-down(xs) {
  .panel {
    flex: 1 1 100%;
    width: 100%;
    height: 100%;

    &.mobile-menu {
      .panel-body {
        padding: 1.5rem $list-group-item-padding-x $list-group-item-padding-y;
      }
    }
  }

  .panel-body {
    height: 100%;
    border-top: 1px solid $dark-shade-ligthen;
    border-bottom: 1px solid $dark-shade-ligthen;
  }
}

// User list
.user-item-user-media {
  height: 194px;
  max-height: 194px;
  position: relative;
}

// Tooltip

.sound-tooltip {
  left: 6% !important;
}

.roundabout-tooltip {
  font-size: 12px;
  position: relative;
  z-index: 1000;

  &.invite-mail-button {
    .tooltip-inner {
      min-width: 200px;
    }
  }
}

.tooltip-inner {
  position: absolute;
  max-width: unset;

  .tooltip-arrow:before {
    content: '';
    position: absolute;
  }

  &.right {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 15px);

    .tooltip-arrow:before {
      left:-8px;
      top: calc(50% - 7px);

      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right:8px solid black;
    }
  }

  &.left {
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 10px);

    .tooltip-arrow:before {
      right:-8px;
      top: calc(50% - 7px);

      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left:8px solid black;
    }
  }

  &.top {
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);

    .tooltip-arrow:before {
      bottom: -8px;
      left: calc(50% - 7px);

      border-top: 8px solid black;
      border-left: 8px solid transparent;
      border-right:8px solid transparent;
    }
  }

  &.bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    .tooltip-arrow:before {
      top: -8px;
      left: calc(50% - 10px);

      border-left: 8px solid transparent;
      border-bottom: 8px solid black;
      border-right:8px solid transparent;
    }
  }
}

// VideoPlayer

.video-player {
  position: relative;
  width: 100%;
  height: 100%;

  .video-player-outer {
    height: 100%;
    margin: 0 auto;

    .video-player-inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      height: 0;

      .video-player-element {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }

      video {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.video-overlay {
  position: absolute;
  top: 0;
  bottom: -1px;
  right: 0;
  left: 0;

  justify-content: space-between;
  flex-direction: column;
  display: flex;

  > div {
    padding: 0.5rem;
    line-height: 0.875rem;
    height: 35px;

    &.top {
      background: linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 95%);
    }

    &.bottom {
      background: linear-gradient(0deg, rgba(0,0,0,.75) 0%, rgba(0,0,0,0) 95%);
    }
  }

  .video-overlay-menu {
    display: flex;
    justify-content: space-between;

    .btn {
      padding: 0;
      font-size: 1.0625rem;
      line-height: 1.0625rem;

      &.btn-icon {
        border: 0;
      }

      &:not(:last-child) {
        margin-right: 0.75rem;
      }
    }
  }

  .username-ellipsis {
    font-size: 0.6875rem;
    line-height: 0.875rem;
    font-weight: 600;
    text-overflow: ellipsis;
    text-shadow: 0.5px 0.5px 0.5px #000000;

  }
}

// User List
.list-group-item {
  border-right: 0;
  border-left: 0;

  &:first-child {
    border-top: 0;
  }
}

.user-item-collapsed {
  display: flex;

  .btn-icon {
    font-size: 0.875rem;
  }
}

.user-item-username {
  color: $white;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-item-primary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-item-secondary {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
}
.user-item-right {
  margin-left: 0.625rem;
  font-size: 0.6875rem;
  line-height: 1.2rem;
  font-weight: 500;
  width: 100%;
}

.alertButton {
  font-size: 18px;
  width: 100%;
}

.Toastify .Toastify__toast-container {
  width: 500px;
}

.video-overlay .video-overlay-menu.bottom > div {
  padding: 0.5rem;
}

// Placeholder help

.placeHolderHelp {
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .noModerator-image {
    top: -10%;
  }

  .tutorial-image {

  }
  .tutorial-header,
  .noModerator-header {
    font-weight: 700;
    margin-top: 15px;
  }
  .tutorial-text,
  .noModerator-text {
    max-width: 400px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

// Grid
.grid {
  width: 100%;
  height: 100%;
}

.grid-row {
  display: flex;
}

.grid-col {
  flex: 1 1 100%;
  height: 100%;
  font-size: 30px;
  padding: 0.125rem;

  &:first-child:last-child {
    padding: 0;
  }
}

// Chat
.chat {
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
}

.chat-header {
  border-top: 3px solid #1A1C22;
}

.chat-header,
.userlist-header {
  position: relative;
  height: 30px;
  padding: 10px 0 0 25px;
  p {
    text-transform: uppercase;
    font-size: 12px;
  }

  .closePanelButton {
    height: 20px;
    width: 15px;
    padding: 0;
    position: absolute;
    top: 8px;
    line-height: 0.6;
    right: 0;
    background: transparent url('../closeIcon.svg') center center no-repeat padding-box;

    &:focus {
      box-shadow: none;
    }

  }
}

.checkboxWrapper {
  margin-top: 10px;

  label {
    margin-left: 10px;

    span {
      position: relative;
      top: -2px;
    }
  }
}

.scrollbar.userlist {
  height: calc(100% - 30px);
}

.chat-data {
  padding-left: 0.4375rem;
  max-width: calc(100% - 1.875rem);
  flex: 1 0 calc(100% - 1.875rem);
}

.chat-messages {
  position: relative;
  height: 100%;
  padding: 1.5rem $list-group-item-padding-x $list-group-item-padding-y;
}

.chatNoMessages {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chatMessageLink {
  color: $primary;

  &:hover {
    color: darken($primary, 10%)
  }
}

.chat-message {
  &:not(:last-child) {
    margin-bottom: 2.1875rem;
  }
  &:last-child {
    margin-bottom: 1.5rem;
  }
}

.chat-message-avatar {
  flex: 0 0 1.875rem;
}

.chat-message-username {
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
}

.chat-message-message {
  overflow-wrap: break-word;
  //font-size: 0.625rem;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1rem;
}

.chat-footer {
  padding: 0 $list-group-item-padding-x $list-group-item-padding-y;

  .input-group-text {
    font-size: 0.6875rem;
    padding: $list-group-item-padding-y $input-padding-x;
  }
}

.chat-input {
  resize: none;
  padding:  $list-group-item-padding-y $list-group-item-padding-x;
  font-size: 0.6875rem;

  @include scrollbar;
}




// Scrollbar
.scrollbar {
  overflow-y: auto;
  height: 100%;

  @include scrollbar;
}

// Settings

.settings {
  padding: 0.625rem $list-group-item-padding-x $list-group-item-padding-y ;
  .btn-group {
    margin: 0 auto;
    .btn {
      min-width: 86px;
      font-size: 0.75rem;
      font-weight: 700;
      padding: 0.8125rem auto;
      line-height: 0.75rem;

      &:not(:last-child) {
        margin-right: 0.125rem;
      }
    }
  }
  .inviteLinks {
    .btn {
      min-width: 86px;
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-weight: 700;

      &:not(:last-child) {
        margin-bottom: 0.125rem;
      }
    }

    .inviteButton {
      width: 100%;
    }
  }
}

// VolumeChanger
$slider-width: 8px;
$slider-height: 8px;
$slider-padding: 2px;
$thumb-size: calc(#{$slider-height} + 2 * #{$slider-padding});

.slider {
  position: relative;
  padding: $slider-padding 0;

  .thumb {
    border: 1px solid #ccc;
    border-radius: 50%;
    width: $thumb-size;
    height: $thumb-size;
    margin-left: calc(#{$thumb-size} / 2 * -1);
    position: absolute;
    top: 0;
    left: 0%;
    background-color: $white;
    cursor: pointer;
  }

  .progress {
    height: $slider-height;

    .progress-bar {
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
}

.user-list {

  .iconWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .volume-changer {
    width: 80px;

    .volume-changer-slider {
      width: 40px;
    }
  }
}

.bottom.video-overlay-menu {
  .volume-changer {
    > button.btn-icon {
      margin: 0;
    }
  }
}

.volume-changer {
  width: 200px;

  .volume-changer-slider {
    width: 100px;
  }
}

// Mobile Menu
.mobile-menu {
  z-index: 1001;
  position: fixed;
  top: 54px;
  right: 0;
  bottom: 0;
  height: calc(100% - 100px);
  width: 280px;
  border-left: 1px solid $dark-shade-ligthen;
}
.modal {
  max-height: 100vh;
  overflow: auto;
}
// Modal
@media (min-width: 768px) {
  .modal-dialog {
    max-width: 750px;
    margin: 1.75rem auto;
  }
}


.modal-header {
  border: none;
  position: relative;

  .close {
    right: 25px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - 2 * #{$modal-header-padding});
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
  }
}

// Nav tabs
.tabs {
  padding-top: 1.5rem;
}

.nav-tabs  {
  justify-content: space-evenly;
  flex-wrap: nowrap;

  .nav-item {
    flex: 1 1 100%;
    text-transform: uppercase;
    line-height: 16px;

    a {
      padding: 0.625rem;
    }
  }

  .nav-link {
    text-align: center;

    @include hover-focus {
      margin-top: $nav-tabs-border-width;
      border: none;
      border-bottom: $nav-tabs-border-width solid $primary;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    font-weight: bold;
    text-align: center;
    margin-top: $nav-tabs-border-width;
    border: none;
    border-bottom: $nav-tabs-border-width solid $primary;
  }
}

// Code
code, .code {
  margin: $spacer auto;
  display: inline-block;
  padding: $spacer;
  color: $code-color;
  font-size: $code-font-size;
  border: 1px solid $dark-shade-ligthen;
  background-color: $dark-shade-accent;
  border-radius: $border-radius;
  cursor: pointer;
}

.volume-meter .progress .progress-bar {
  -webkit-transition: width 0.2s ease;
  -moz-transition: width 0.2s ease;
  -ms-transition: width 0.2s ease;
  -o-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

// Toastify

.roundabout-warning-border-left {
  border-left: 8px solid #FD7E0E;
}

.roundabout-error-border-left,
.roundabout-danger-border-left {
  border-left: 8px solid #FF3939;
}

.roundabout-success-border-left {
  border-left: 8px solid #32C532;
}

.roundabout-toast-icon {
  position: absolute;
}

.roundabout-toast {
  padding: 20px;

  &:hover {
    cursor: default;
  }

  .roundabout-button-toast-header {
    font-weight: 600;
    font-size: 16px;
  }

  .roundabout-toast-header {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    line-height: 2;
  }

  .roundabout-toast-close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 50px;
    padding: 0;
    line-height: .5;
    background-color: transparent;
    border: transparent;

    &.roundabout-toast-close-button-warning {
      color: #FD7E0E;

      &:hover {
        color: darken(#FD7E0E, 10%)
      }
    }
    &.roundabout-toast-close-button-error,
    &.roundabout-toast-close-button-danger {
      color: #FF3939;

      &:hover {
        color: darken(#FF3939, 10%)
      }
    }
    &.roundabout-toast-close-button-success {
      color: #32C532;

      &:hover {
        color: darken(#32C532, 10%)
      }
    }
  }

  .roundabout-toast-inner-body {
    padding-left: 50px;
    padding-right: 50px;
  }

  &.roundabout-toast-warning {

    .roundabout-toast-button-warning {
      color: white;
      background-color: #FD7E0E;
      height: 50px;
      padding: 5px;
      width: 250px;
      font-size: 16px;

      &:hover {
        background-color: darken(#FD7E0E, 10%)
      }
    }

    .roundabout-toast-highlight-text-warning {
      color: #FD7E0E;

      &:hover {
        color: darken(#FD7E0E, 10%)
      }
    }
  }

  &.roundabout-toast-error,
  &.roundabout-toast-danger {

    .roundabout-toast-button-error,
    .roundabout-toast-button-danger {
      color: white;
      background-color: #FF3939;
      height: 50px;
      padding: 5px;
      width: 250px;
      font-size: 16px;

      &:hover {
        background-color: darken(#FF3939, 10%)
      }
    }

    .roundabout-toast-highlight-text-error,
    .roundabout-toast-highlight-text-danger {
      color: #FF3939;

      &:hover {
        color: darken(#FF3939, 10%)
      }
    }
  }

  &.roundabout-toast-success {

    .roundabout-toast-button-success {
      color: white;
      background-color: #32C532;
      height: 50px;
      padding: 5px;
      width: 250px;
      font-size: 16px;

      &:hover {
        background-color: darken(#32C532, 10%)
      }
    }

    .roundabout-toast-highlight-text-success {
      color: #32C532;

      &:hover {
        color: darken(#32C532, 10%)
      }
    }
  }

}

@media (max-width: 420px) {
  div#root {
    background-color: $dark-shade;
  }

  nav.navbar.bg-dark-shade.text-light {
    border-bottom: 1px solid $dark-shade-ligthen;
    a {
      border-right: none;
    }
  }

  #root div.mobile-toolbar.bg-dark-shade {
    border-top: 1px solid $dark-shade-ligthen;
  }

  div.Toastify .Toastify__toast-container {
    width: 100%;
  }

  .code.inviteLinkInput {
    width: 55%;
  }

  .mobileMenuButton {
    &:focus {
      box-shadow: none;
    }
  }

  .nav.nav-tabs {
    justify-content: normal;
    flex-wrap: wrap;
    border-bottom: none;
  }

  .nav-tabs .nav-item {
    margin-bottom: 10px;
    width: 100%;
  }

  .closePanelButton {
    display: none;
  }

}

.Toastify {
  div {
    .Toastify__toast--default {
      background-color: #1A1A27;
    }
    .Toastify__progress-bar--warning {
      background-color: #FD7E0E;
    }
    .Toastify__progress-bar--error,
    .Toastify__progress-bar--danger {
      background-color: #FF3939;
    }
    .Toastify__progress-bar--success {
      background-color: #32C532;
    }

    .Toastify__toast--warning,
    .Toastify__toast--success,
    .Toastify__toast--danger,
    .Toastify__toast--error {
      background-color: #1A1A27;
    }
  }
}

.mobileMenuIcon {
  padding: 3px;
}

.MobileMenuButtonText {
  margin-left: 16px;
  margin-bottom: 0;
}

.btn-mobile-menu {
  width: 100%;
  padding: 0;
  height: 60px;
  display: flex;
}

.btn-mobile-menu-end {
  position: absolute;
  bottom: 0;
}

// Toastify
//.toastify-content--info { border-color: #yourColor; }
//.Toastify__toast--success {
//  background: $primary !important;
//  border-color: $primary;
//}
//.toastify-content--warning {border-color: #yourColor;  }
//.toastify-content--error {border-color: #yourColor ; }
